import React from "react";
import Modal from "react-modal";
import config from "../config";
// import ReactPlayer from "react-player";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const DisplayData = ({ ...props }) => {
  const customer = props.customer;
  const records = customer.cdr.map((each_record, idx) => {
    let audioPlay = null;

    if (each_record.billsec) {
      let d = each_record.calldate.split("T")[0];
      const url =
        config.url_get_audio +
        "getAudioRange?s=" +
        each_record.linkedid +
        "&client_id=" +
        customer.id +
        "&src=" +
        each_record.src +
        "&calldate=" +
        d;

      // audioPlay = <ReactPlayer height="100px" url={url} controls={true} />;
      audioPlay = <AudioPlayer key={`audio-idx-${idx}`} src={url} />;
    }

    const durata_conversatie =
      each_record.disposition === "ANSWERED" && each_record.billsec > 0
        ? Math.floor(each_record.billsec / 60) +
          " minute si " +
          (each_record.billsec % 60) +
          " secunde"
        : " - ";

    return (
      <tr
        key={`idx-${idx}`}
        style={
          each_record.disposition === "ANSWERED"
            ? { backgroundColor: "#27ae60", color: "white" }
            : { backgroundColor: "#34495e", color: "white" }
        }
      >
        <td>{each_record.calldate}</td>
        <td>{each_record.disposition}</td>
        <td>{durata_conversatie}</td>
        <td>{each_record.src} </td>
        <td style={{ width: "450px" }}>{audioPlay}</td>
      </tr>
    );
  });

  return (
    <Modal
      isOpen={props.isOpen}
      contentLabel="CDR"
      ariaHideApp={false}
      onRequestClose={props.closeModal}
    >
      <h1>Detalii client ID: {customer.id}</h1>
      <h3>Inregistrari: </h3>
      <table className="table table-stripped table-bordered">
        <thead>
          <tr>
            <th>Cand</th>
            <th>Ultima dispozitie</th>
            <th>Durata conversatie</th>
            <th>Telefon</th>
            <th>Inregistrare</th>
          </tr>
        </thead>
        <tbody>{records}</tbody>
      </table>

      <button onClick={props.closeModal}>Inchide</button>
    </Modal>
  );
};

export default DisplayData;
