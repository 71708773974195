import React, { useState, useEffect } from "react";

import axios from "axios";
import Searching from "./components/Searching";
import DisplayData from "./components/DisplayData";
import DataList from "./components/DataList";
import config from "./config";

import "./App.css";
const SCT = "#BC_vOiCe_20";

const getTokenFromUrl = () => {
  const url = window.location.href;

  const captured = /token=([^&]+)/.exec(url);

  if (captured && captured.length > 1) {
    return captured[1];
  }

  return false;
};
const App = () => {
  const [stateApp, setStateApp] = useState({
    isLogged: false,
    customers: [],
    error: "",
    isLoading: true,
    openModal: false,
    customer: {},
    total: 0,
    isSearching: false,
    token: null,
    offset: 0,
    term: "",
  });

  const searching = (search) => {
    const { term, offset } = stateApp;

    // search also can by "" so we need to check if search is undefined
    const q = search || term;
    let url =
      config.url_customer +
      "customers?token=" +
      getTokenFromUrl() +
      "&q=" +
      q +
      "&offset=" +
      offset;

    setStateApp((prev) => ({
      ...prev,
      isSearching: true,
    }));

    axios
      .get(url)
      .then(function (response) {
        const { customers, total } = response.data;
        setStateApp((prev) => ({
          ...prev,
          customers,
          total,
          isSearching: false,
          isLoading: false,
        }));
      })
      .catch(function (error) {
        setStateApp((prev) => ({
          ...prev,
          error: error.response ? error.response.data.message : "Eroare api!",
          isSearching: false,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    const token_from_url = getTokenFromUrl();
    if (token_from_url) {
      setStateApp((prev) => ({
        ...prev,
        token: token_from_url,
      }));

      searching();
    }
  }, []);

  const closeModal = () => {
    setStateApp((prev) => ({
      ...prev,
      openModal: false,
    }));
  };

  const prepareSearching = async (term) => {
    setStateApp((prev) => ({
      ...prev,
      offset: 0,
      term,
    }));

    setTimeout(() => {
      searching(term);
    }, 200);
  };

  const onClick = (id) => {
    let customer = stateApp.customers.find((customer) => customer.id === id);

    setStateApp((prev) => ({
      ...prev,
      openModal: true,
      customer: customer,
    }));
  };

  const nextPage = async () => {
    const offset = stateApp.offset + 1;

    setStateApp((prev) => ({
      ...prev,
      offset,
    }));

    searching();
  };

  const previousPage = async () => {
    if (stateApp.offset === 0) return false;

    const offset = stateApp.offset - 1;
    setStateApp((prev) => ({
      ...prev,
      offset,
    }));

    searching();
  };

  const displayLoading = () => {
    return (
      <div
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "50px",
          marginTop: "200px",
        }}
      >
        <object data="loading.svg" type="image/svg+xml"></object>
      </div>
    );
  };

  const putHerePassword = () => {
    const displayError = stateApp.error ? (
      <div className="alert alert-danger">{stateApp.error}</div>
    ) : null;

    return (
      <div className="row">
        <div className="col-md-3 col-md-offset-3">
          {displayError}
          <div className="form-row" style={{ margin: 10 }}>
            <div className="form-group">
              <label>Parola de acces: </label>
              <input
                type="password"
                placeholder="Parola de acces"
                className="form-control"
                style={{ marginLeft: 10 }}
                onKeyPress={(e) => {
                  const value = e.target.value;

                  if (SCT === value) {
                    setStateApp((prev) => ({
                      ...prev,
                      isLogged: true,
                      error: "",
                    }));
                  } else {
                    setStateApp((prev) => ({
                      ...prev,
                      error: "Parola este gresita.",
                    }));
                  }
                }}
              />
              <small className="ml-3">Folositi tasta Enter</small>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (!stateApp.isLogged) {
    return putHerePassword();
  }

  if (!stateApp.token) {
    return <h1>No token provided!</h1>;
  }

  const error = stateApp.error ? (
    <h2 style={{ color: "red" }}>Eroare: {stateApp.error.toString()}</h2>
  ) : null;

  if (stateApp.isLoading) {
    return displayLoading();
  }

  const title =
    "Nr total de inregistrari: " +
    stateApp.total +
    " si afisata pe pagina:" +
    (stateApp.offset + 1) +
    " un numar de  " +
    stateApp.customers.length +
    " de inregistrari.";

  const pagina = "Pagina: " + (stateApp.offset + 1);
  const next = (
    <button
      disabled={stateApp.isSearching || stateApp.customers.length < 100}
      className="btn btn-primary"
      onClick={() => {
        nextPage();
      }}
    >
      Pagina urmatoare
    </button>
  );
  const previous = (
    <button
      disabled={stateApp.isSearching || stateApp.offset === 0}
      className="btn btn-danger"
      onClick={() => {
        previousPage();
      }}
    >
      Pagina dinainte
    </button>
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <h1>{title}</h1>
          <h2>{pagina}</h2>
          {previous} {next}
          {error}
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <Searching
            search={prepareSearching}
            isLoading={stateApp.isSearching}
          />
        </div>
        <div className="col-md-12">
          {stateApp.isLoading === false ? (
            <DataList data={stateApp.customers} clicked={onClick} />
          ) : null}
        </div>
      </div>

      {stateApp.openModal && (
        <DisplayData
          customer={stateApp.customer}
          closeModal={closeModal}
          isOpen={stateApp.openModal}
        />
      )}
      <div style={{ float: "right" }}>
        {previous} {next}
      </div>
      <br />
      <br />
    </div>
  );
};

export default App;
