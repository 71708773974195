import React from "react";
import ListItem from "./ListItem";

const DataList = ({ data, clicked }) => {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>ID Client</th>
          <th>Proiect</th>
          {data?.[0].proiect_id === 373 && <th>Nume firma</th>}
          <th>Ultima accesare</th>
          <th>Ultimul status</th>
          <th>Telefon</th>
          <th>Inregistrari</th>
        </tr>
      </thead>
      <tbody>
        {data.map((d) => (
          <ListItem key={d.id} data={d} clicked={clicked} />
        ))}
      </tbody>
    </table>
  );
};

export default DataList;
