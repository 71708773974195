import React, { Component } from "react";

const ListItem = ({ data, clicked }) => {
  let d = data;
  let has_cdr = 0;

  d.cdr.forEach((cdr) => {
    if (cdr.billsec) has_cdr += 1;
  });

  const style = { backgroundColor: "#34495e", color: "white" };

  return (
    <tr style={style}>
      <td>{d.id}</td>
      <td>{d.nume_proiect}</td>
      {d.proiect_id === 373 && <td>{d.detalii.firma || "unknown"} </td>}
      <td>{d.data_modificare}</td>
      <td>{d.status} </td>
      <td>{d.telefon} </td>
      <td onClick={() => clicked(d.id)}>Vezi inregistrari</td>
    </tr>
  );
};

export default ListItem;
