import React from "react";
import debounce from "lodash/debounce";
// rewrite this

const Searching = ({ search, isLoading }) => {
  const searching = debounce((e) => {
    search(e.target.value);
  }, 500);
  const loading = isLoading ? (
    <span style={{ marginLeft: 10 }}>Loading ...</span>
  ) : null;
  return (
    <div className="form-inline" style={{ margin: 10 }}>
      <div className="form-group">
        <label>Cautare: </label>
        <input
          type="text"
          placeholder="Cautare ..."
          className="form-control"
          style={{ marginLeft: 10 }}
          onKeyPress={searching}
        />
        {loading}
      </div>
    </div>
  );
};

class SearchingOld extends React.Component {
  constructor(props) {
    super(props);

    this.searching = debounce(this.searching.bind(this), 500);
  }

  searching() {
    let s = this.refs.searching.value;
    this.props.search(s);
  }

  render() {
    const loading = this.props.isLoading ? (
      <span style={{ marginLeft: 10 }}>Loading ...</span>
    ) : null;
    return (
      <div className="form-inline" style={{ margin: 10 }}>
        <div className="form-group">
          <label>Cautare: </label>
          <input
            type="text"
            ref="searching"
            placeholder="Cautare ..."
            className="form-control"
            style={{ marginLeft: 10 }}
            onKeyPress={this.searching}
          />
          {loading}
        </div>
      </div>
    );
  }
}

export default Searching;
